import { useState } from 'react';
import logo from './logo.png';

export default function RequestReset() {

  const [values, setValues] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/request-token`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(values)
    }).then(x => {
      setSubmitted(true)
    })
  }

  return (
    <>
      {submitted && <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="w-auto mx-auto h-44"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-24 text-3xl font-bold tracking-tight text-center text-purple-500">Reset Password</h2>
          <p className="mt-2 text-sm text-center text-purple-400">
            Or{' '}
            <a href="/login" className="font-medium text-purple-300 hover:text-purple-400">
              login
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-center text-purple-700">
                  If that account exists we have sent you an email with instructions to reset your password
                </label>
              </div>


            </div>

          </div>
        </div>
      </div>}
      {!submitted && <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="w-auto mx-auto h-44"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-24 text-3xl font-bold tracking-tight text-center text-purple-500">Reset Password</h2>
          <p className="mt-2 text-sm text-center text-purple-400">
            Or{' '}
            <a href="/login" className="font-medium text-purple-300 hover:text-purple-400">
              login
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white sm:rounded-lg sm:px-10">
            <div className="space-y-6">

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-purple-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full px-3 py-2 placeholder-purple-400 border border-purple-300 rounded shadow-sm appearance-none focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                  />
                </div>
              </div>


              <div>
                <button
                  onClick={() => submit()}
                  type="button"
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-purple-500 border border-transparent rounded shadow-sm hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>}
    </>
  )
}
