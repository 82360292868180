import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import SelectField from "./fields/select-field";
import PasswordField from "./fields/password-field";
import { useNavigate, useParams } from "react-router-dom";

export default function UserForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    let id = params.id ?? props.id;

    return <div>
        <PageHeader headline="Manage User"></PageHeader>
        <FormWrapper url="admin/user" id={id} {...props} callback={() => navigate('/users')}>
            {(values, setValues) =>
                <>
                    <div className="grid grid-cols-2 gap-2">
                        <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <TextField label="Email" value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>
                    </div>
                    
                    <PasswordField label="New Password" value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                </>
            }
        </FormWrapper>
    </div>
}