import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class Video extends Component {
    render () {
        return (
        <div className='relative player-wrapper'>
            <ReactPlayer
            light={<img src='/placeholder.png' width="100%" height="100%" />}
            className='react-player fixed-bottom'
            url= '/videos/kazang.mp4'
            width='100%'
            height='100%'
            controls = {true}
            playing
            />
        </div>
        )
    }
}

export default Video;