import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react'
import { api } from '../../api.service';
import Loading from '../../components/loading';

export default function SelectField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, onBlur, missing=false }) {

    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {
        
    }, [])
    const [query, setQuery] = useState('')

    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))
    if (!selectOptions) return <Loading></Loading>

    return <div className={className}>
        <label className={`block text-sm font-medium leading-6 text-white ${labelClass}`}>
            {label}
        </label>
        <div className="relative mt-2 rounded shadow-sm ">
            <Combobox value={selectOptions.find(x => x.value === value)} onChange={(e) => { onChange(e); }}>
                <div className="relative mt-1">
                    <div className="relative w-full rounded  cursor-default overflow-hidden border border-gray-600 bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-purple-300 sm:text-sm">

                        <Combobox.Input
                            className={`${missing ? 'bg-red-500' : 'bg-neutral-800'} w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-white focus:ring-0`}
                            onChange={(e) => setQuery(e.target.value)}
                            displayValue={(x) => x?.text}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded bg-neutral-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filtered.map((x) => (
                                <Combobox.Option
                                    key={x.value}
                                    value={x.value}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 px-3  ${x.value === value ? 'bg-purple-600 text-white' : 'text-white'} ${x.value !== value && active ? 'bg-purple-400 text-white' : 'text-white'}`
                                    }
                                >
                                    {x.text}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>

                    </Transition>
                </div>
            </Combobox>
        </div>
    </div>
}

