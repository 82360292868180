export default function CheckboxField({ label, value, onChange, className, inputClass, labelClass, inline, onBlur }) {
    return <div className={className + (inline ? ' flex place-items-center' : '')}>
        {!inline && <label className={`block text-sm font-medium leading-6 text-white ${labelClass}`}>
            {label}
        </label>}
        <div className={(inline ? '' : 'mt-2')}>
            <input onBlur={onBlur} type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} className={`${inline ? 'w-7 h-7' : 'w-9 h-9'} block rounded border-0 p-1.5 bg-neutral-800 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6 ${inputClass}`} />
        </div>
        {inline && <label className={`ml-3 block text-sm font-medium leading-6 text-white ${labelClass}`}>
            {label}
        </label>}
    </div>
}