import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from './logo.png';

export default function Register() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [values, setValues] = useState({});
  const [error, setError] = useState("")

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ user: values })
    }).then(res => { return res.json() }).then(x => {
      if (x.error) {
        if (x.error == "email_in_use") {
          setError("Email already in use. Please sign in or register with a different email.");
        }
      }
      else if (x.token) {
        localStorage.setItem("token", x.token);
        window.location.replace(x.redirectUrl)
      }
      else {
        setError("Registration failed. Please check your details and try again.")
      }
    })
  }

  return (
    <>
      <div className="flex flex-col min-h-screen bg-dark">
        <div className="flex flex-col items-center justify-center flex-1">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="w-64 h-auto mx-auto mb-10"
              src={logo}
            />
          </div>
          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="px-4 py-8 rounded shadow bg-neutral-700 sm:px-10">
              <div className="text-xl font-medium text-center text-white">Create your account</div>
              <div className="my-5 text-sm font-light text-center text-light">Already have an account? <a className="text-purple-300 hover:text-purple-200" href="/login">Sign In</a></div>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-center text-white">
                    {error}
                  </label>
                </div>

                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-white">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })}
                      id="name"
                      name="name"
                      autoComplete="name"
                      required
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-white">
                    Email address <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-white">
                      Password <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                        type="password"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-white">
                      Confirm Password <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input value={values.confirmPassword} onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
                        type="password"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <input checked={values.tos} onChange={(e) => setValues({ ...values, tos: e.target.checked })}
                    type="checkbox"
                    className="block appearance-none rounded border mt-0.5 border-gray-300  placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                  />

                  <div className="ml-2 text-sm text-light">
                    I accept the platform <a href="/terms" className="text-purple-200 hover:text-purple-300">Terms of Service</a>
                  </div>
                </div>

                <div>
                  <button
                    onClick={() => submit()}
                    type="button"
                    disabled={!values.email || !values.password || values.password != values.confirmPassword || !values.tos || !values.name}
                    className="flex justify-center w-full px-4 py-2 font-semibold text-white bg-purple-500 border border-transparent rounded shadow-sm disabled:bg-gray-500 text-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
