import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import {api, api_delete, api_update} from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import { toast } from 'react-toastify';

export default function AdminAbout() {
    const navigate = useNavigate();
    const [aboutUsBodyContent, setAboutUsBodyContent] = useState('');
    const [aboutUsTitleContent, setAboutUsTitleContent] = useState('');
    const [aboutUsSignoffContent, setAboutUsSignoffContent] = useState('');
    const [deleteId, setDeleteId] = useState();

    useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/about`).then(x => {
        setAboutUsTitleContent(x.about[0]?.title);
        setAboutUsBodyContent(x.about[0]?.body);
        setAboutUsSignoffContent(x.about[0]?.signoff);
    } ); }, [])

    const handleSave = () => {
        // Save the new About Us content when the Save button is clicked
        api_update(`${process.env.REACT_APP_API_URL}/admin/about`, {data: {
            body: aboutUsBodyContent.toString(), title: aboutUsTitleContent.toString(), signoff: aboutUsSignoffContent.toString()
        }}).then(x => {
            toast.success('About successfully updated.');
        });
    };

    return (
        <div>
            <h1>TITLE</h1>
            <textarea
                value={aboutUsTitleContent}
                onChange={e => setAboutUsTitleContent(e.target.value)}
                rows={5}
                cols={100}
            />
            <h1>BODY</h1>
            <textarea
                value={aboutUsBodyContent}
                onChange={e => setAboutUsBodyContent(e.target.value)}
                rows={10}
                cols={100}
            />
            <h1>SIGNOFF</h1>
            <textarea
                value={aboutUsSignoffContent}
                onChange={e => setAboutUsSignoffContent(e.target.value)}
                rows={3}
                cols={100}
            />
            <button onClick={handleSave}><u>Save</u></button>
        </div>
    )
}