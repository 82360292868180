import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";

import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";

export default function SongForm(props) {
    const params = useParams();
    const navigate = useNavigate();

    return <div className="max-w-6xl mx-auto">

        <div className="my-8 text-5xl font-black text-white">Add a Song</div>
        <div className="my-8 text-lg font-semibold text-light">You can add songs you like that aren’t on the KaZang platform. When you add a song it will appear in your Library and Liked Songs list.</div>


        <FormWrapper url="user/song" noStyle={true} id={'none'} noId {...props} callback={() => {navigate('/profile/me');}}>
            {(values, setValues, submit, fieldsMissing) =>
                <div className="flex flex-col space-y-2 text-white">
                    <TextField label="Song Name" value={values.name} onChange={(val) => setValues({...values, name: val})} missing={fieldsMissing}></TextField>
                    <TextField label="Artist" value={values.artist} onChange={(val) => setValues({ ...values, artist: val })} missing={fieldsMissing}></TextField>
                    <TextField label="Full Youtube URL" value={values.url} onChange={(val) => setValues({ ...values, url: val })} missing={fieldsMissing}></TextField>
                    <SelectField options={[
                        { value: 'Argentina', text: 'Argentina' },
                        { value: 'Australia', text: 'Australia' },
                        { value: 'Brazil', text: 'Brazil' },
                        { value: 'Canada', text: 'Canada' },
                        { value: 'Colombia', text: 'Colombia' },
                        { value: 'Dominican Republic', text: 'Dominican Republic' },
                        { value: 'El Salvador', text: 'El Salvador' },
                        { value: 'France', text: 'France' },
                        { value: 'Germany', text: 'Germany' },
                        { value: 'India', text: 'India' },
                        { value: 'Italy', text: 'Italy' },
                        { value: 'Japan', text: 'Japan' },
                        { value: 'Kenya', text: 'Kenya' },
                        { value: 'Mexico', text: 'Mexico' },
                        { value: 'Netherlands', text: 'Netherlands' },
                        { value: 'Nigeria', text: 'Nigeria' },
                        { value: 'South Africa', text: 'South Africa' },
                        { value: 'South Korea', text: 'South Korea' },
                        { value: 'Spain', text: 'Spain' },
                        { value: 'Sweden', text: 'Sweden' },
                        { value: 'Tanzania', text: 'Tanzania' },
                        { value: 'Uganda', text: 'Uganda' },
                        { value: 'United Kingdom', text: 'United Kingdom' },
                        { value: 'United States', text: 'United States' },
                        { value: 'Zimbabwe', text: 'Zimbabwe' },
                        { value: 'Other', text: 'Other' },
                    ]} label="Country" value={values.chart} onChange={(val) => setValues({ ...values, chart: val })} missing={fieldsMissing} />
                </div>
            }
        </FormWrapper>
    </div>
}