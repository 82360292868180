import { PlayIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlayer } from './App'
import { api } from './api.service'
export default function Chart() {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const player = usePlayer();
  const params = useParams();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/charts/${params.country.toLowerCase()}`).then(x => { { setData(x.songs?.filter(x => x.rank && x.rank > 0 && x.rank <= 100 && x.chart?.toLowerCase() == params.country?.toLowerCase())); } }) }, [params])
  const getYoutubeVideoId = (url) => {
    return url.split('v=')[1]
  }
  return (<div className="flex flex-col h-full">
    <div className="flex flex-col max-w-6xl mx-auto">
      <div className="my-2 mt-5 text-xl font-medium text-white capitalize">{params.country}</div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:grid-cols-6">
        {data?.map((x, i) => <div onClick={(e) => { e.stopPropagation(); player.setSong({ ...x, id: getYoutubeVideoId(x.url) }) }}  className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600">

          <div onClick={(e) => { e.stopPropagation(); player.ChangeMode("Chart", params.country); player.setSong({ ...x, id: getYoutubeVideoId(x.url) }); player.setQueue(data?.sort((a, b) => a.rank - b.rank).filter((x, j) => j > i).map(x => { return { ...x, id: getYoutubeVideoId(x.url) } })) }} className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
            <PlayIcon className="w-8 text-white" />
          </div>
          <div className="pt-1 pl-2 text-xs font-medium text-light">{i + 1}</div>

          <div className="flex px-2 py-1">
            <img className="object-cover rounded" src={`https://img.youtube.com/vi/${getYoutubeVideoId(x.url)}/mqdefault.jpg`}></img>
          </div>
          <div className="p-2">
                <div className='mt-1 text-base font-semibold text-white truncate'>{x.name}</div>
                <div className="text-xs font-medium truncate text-light">{x.artist}</div>
          </div>
        </div>)}
      </div>
    </div>
  </div >)
}