import { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import Video from './components/video';
import logo from './logo-dark.png';
import {api} from "./api.service";
export default function About() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(true)
  const [aboutBody, setAboutBody] = useState();
  const [aboutTitle, setAboutTitle] = useState();
  const [aboutSignoff, setAboutSignoff] = useState();
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/public/about`).then(x => {
      setAboutBody(x.about[0]?.body.replaceAll("\n", "<br />"));
      setAboutTitle(x.about[0]?.title.replaceAll("\n", "<br />"));
      setAboutSignoff(x.about[0]?.signoff.replaceAll("\n", "<br />"));
    })

    if (!localStorage.getItem('token'))
      setLoggedIn(false)
  }, [])

  return (<div className="flex flex-col h-full">
    <div className="flex flex-col max-w-6xl mx-auto">
      <div className="flex items-center justify-between">
        <div className="my-8 text-5xl font-bold text-white">About <img className="inline w-[190px] pb-1 ml-3"
                                                                       src={logo}></img></div>
        {!loggedIn && <div className="flex items-center space-x-3">
          <div className="font-semibold cursor-pointer text-light opacity-70" onClick={() => navigate('/login')}>Log
            in
          </div>
          <div className="p-3 px-5 font-semibold text-black bg-white rounded-full cursor-pointer"
               onClick={() => navigate('/register')}>Sign Up
          </div>
        </div>}
      </div>
      <Video/>

      <p className="my-3 mt-5 text-xl font-medium text-white" dangerouslySetInnerHTML={{__html: aboutTitle}}/>
      <p className="my-2 font-medium text-light" dangerouslySetInnerHTML={{__html: aboutBody}}/>
      <p className="my-2 mb-10 text-xl font-bold text-light" dangerouslySetInnerHTML={{__html: aboutSignoff}}/>

      <div className={(aboutBody ?? false) && 'hidden'}>
        <p className="my-3 mt-5 text-xl font-medium text-white">Explore. Listen. Discover joy in a new song. Connect the
          world … one song at a time.</p>
        <p className="my-2 font-medium text-light">Welcome to KaZang – a music discovery platform that helps people find
          music they love in languages they don’t speak.</p>
        <p className="my-2 font-medium text-light">KaZang breaks barriers to build bridges. We cross frontiers to open
          minds. We look beyond the horizon to discover ourselves. We explore the world through music.</p>
        <p className="my-2 font-medium text-light">KaZang features chart-topping hits from around the world – starting
          with 25 of the world’s most influential music markets.</p>
        <p className="my-2 font-medium text-light">KaZang is for creators, too. Taylor Swift is big in India and the UK.
          But can an Indian artist, singing in Hindi or Punjabi, become a star in the world’s biggest English-language
          markets? It’s possible! What about a hitmaker from Mexico, Brazil, or Nigeria? We aim to help artists
          everywhere find new fans in new markets. With KaZang, expect the unexpected … and from surprising places.</p>
        <p className="my-2 font-medium text-light">Language can be a bridge instead of a barrier, provided the words
          have a melody and a beat that makes you wanna dance.</p>
        <p className="my-2 mb-10 text-xl font-bold text-light">Come dance with us!</p>
      </div>
      <div className="my-10"></div>
    </div>
  </div>)
}