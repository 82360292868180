import { PlayIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from './api.service'
import { usePlayer } from './App'
import Button from './components/button'
import ImageViewer from './components/image-viewer'
import TextAreaField from './forms/fields/text-area-field'
export default function Song() {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const player = usePlayer();
  const params = useParams();
  const [value, setValue] = useState('')
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/song/${params.id}`).then(x => { { setData(x); } }) }, [params])
  const getYoutubeVideoId = (url) => {
    return url.split('v=')[1]
  }

  const postComment = () => {
    api(`${process.env.REACT_APP_API_URL}/user/comment/${params.id}`, { comment: value }).then(x => { setValue(''); api(`${process.env.REACT_APP_API_URL}/public/song/${params.id}`).then(x => { { setData(x); } }) })
  }

  return (<div className="flex flex-col h-full mx-5">

    <div className="flex justify-between">
      <div>
        <div className="my-2 mt-5 text-lg font-semibold text-white capitalize">{data?.song?.chart}</div>
        <div className="my-2 text-6xl font-black text-white">{data?.song?.name}</div>
        <div className="my-2 text-3xl font-medium text-light">{"Artist: " + data?.song?.artist}</div>
        <div
            className="my-1 text-xl font-medium text-light">{"Genres: " + data?.song?.genres.toString().replaceAll(',', ', ')}</div>
        <div>
          {data?.song?.url !== undefined && <img className="object-cover"
                                                 src={`https://i.ytimg.com/vi_webp/${getYoutubeVideoId(data?.song?.url)}/sddefault.webp`}></img>}
        </div>
      </div>
    </div>
    <div className="my-auto mr-auto">
      <div onClick={(e) => {
        e.stopPropagation();
        player.setSong({...data.song, id: getYoutubeVideoId(data.song.url)})
      }}
           className="p-4 transition duration-300 scale-100 bg-purple-400 rounded-full shadow cursor-pointer hover:scale-105 hover:bg-purple-300">
        <PlayIcon className="w-12 text-white"/>
      </div>
    </div>

    <div className="my-6 mt-10 text-2xl font-bold text-white">Comments</div>

    {!data?.comments?.length && <div className="my-10 text-2xl font-semibold text-light ">No Comments Yet</div>}
    <div className="flex-1 lg:overflow-y-scroll">
      <div className="min-h-96">
        {data?.comments?.sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1).map(x => <div
                className="mb-6 text-white">
              <div className="flex items-center mb-2 space-x-2 text-sm font-semibold text-light">
                {x.user?.profilePicture &&
                    <div><ImageViewer className="w-6 h-6 rounded-full" small image={x.user?.profilePicture}/></div>}
                <div className="">{x.user?.name}</div>
                <div className="text-xs font-light text-neutral-200">{moment(x.date).format('MMM DD')}
                </div>

              </div>
              <div className="font-light">{x.comment}</div>
            </div>
        )}
      </div>

    </div>

    <div className="mb-10">
      <TextAreaField placeholder="New Comment..." value={value} onChange={(val) => setValue(val)}></TextAreaField>
      <Button className="mt-5 bg-purple-500 hover:bg-purple-600" text="Post Comment"
              onClick={() => postComment()}></Button>
    </div>
  </div>)
}