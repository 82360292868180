import { PlayIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlayer } from './App'
import { api } from './api.service'
import Button from './components/button'
import TextField from './forms/fields/text-field'

export default function Search() {

  const [data, setData] = useState()
  const [value, setValue] = useState()
  const navigate = useNavigate();
  const player = usePlayer();

  const getYoutubeVideoId = (url) => {
    return url.split('v=')[1]
  }

  const getResults = () => {
    api(`${process.env.REACT_APP_API_URL}/user/song-search/${value}`).then(x => {
      setData(x)
    })
  }

  return (<div className="flex flex-col h-full max-w-6xl mx-auto">

    <div className="flex items-center justify-between">
      <div className="mt-5 text-5xl font-black text-white">Search</div>

    </div>

    <div className="my-2 mt-10 text-xl font-medium text-white">Search For Songs</div>
    <div className="my-2 text-sm font-medium text-light">Search for songs on KaZang by track name or artist</div>

    <div className="flex items-baseline justify-between max-w-lg py-5 space-x-2">
      <TextField className="flex-1" placeholder={'Search...'} value={value} onChange={(val) => setValue(val)}></TextField>

      <Button text="Search" className="bg-purple-400 px-5 py-1.5" onClick={() => getResults()}></Button>
    </div>
    {data?.songs?.length > 0 && <div className="my-2 mt-5 text-xl font-medium text-white">Results</div>}
    {data?.songs?.length > 0 && <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">


      {data?.songs?.map((x, i) => <div onClick={() => navigate('/song/' + x._id)} className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600">
        <div onClick={(e) => { e.stopPropagation(); player.setSong({ ...x, id: getYoutubeVideoId(x.url) }) }} className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
          <PlayIcon className="w-8 text-white" />
        </div>
        <div className="p-2 text-lg font-semibold tracking-wider text-light">{x.chart}</div>
        <div className="flex p-2">
          <img className="object-cover rounded" src={`https://img.youtube.com/vi/${getYoutubeVideoId(x.url)}/mqdefault.jpg`}></img>
        </div>
        <div className="p-2">
          <div className='mt-1 text-base font-semibold text-white truncate'>{x.name}</div>
          <div className="text-xs font-medium truncate text-light">{x.artist}</div>
        </div>
      </div>)}





    </div>}

  </div>)
}