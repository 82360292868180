import { CheckIcon, PlayIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useNavigate } from 'react-router-dom'
import { usePlayer } from './App'
import { api } from './api.service'
import HelpPopup from './components/help-popup'
import ImageViewer from './components/image-viewer'
import {toast} from "react-toastify"
import Flag from 'react-flagkit'

export default function Home() {
  const navigate = useNavigate();

  const [data, setData] = useState()
  const [charts, setCharts] = useState()
  const [networkSongs, setNetworkSongs] = useState()
  const player = usePlayer();
  const [shuffledTopSongs, setShuffledTopSongs] = useState([])
  const [shuffledTopSongs2, setShuffledTopSongs2] = useState([])
  const [shuffled1Songs, setShuffled1Songs] = useState([])
  const [genrePreview, setGenrePreview] = useState()
  const [loggedIn, setLoggedIn] = useState(true)
  const [requests, setRequests] = useState()
  const [welcomeMessage, setWelcomeMessage] = useState()
  const [welcomeFlag, setWelcomeFlag] = useState()
  const [welcomeCountry, setWelcomeCountry] = useState()

  const [ignoredAbout, setIgnoredAbout] = useState(localStorage.getItem("kigab"));

  const genres = ["pop", "rock", "hip-hop", "afropop", "electronica", "movies"]

  const acceptRequest = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/accept-request/${id}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/user/get-requests-home`).then(x => { setRequests(x) })
    }).then(x => {toast.success('Friend request accepted')})
  }

  const rejectRequest = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/reject-request/${id}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/user/get-requests-home`).then(x => { setRequests(x) })
    })
  }

  const clickIgnoreAbout = () => {
    setIgnoredAbout(true);
    localStorage.setItem("kigab", "true")
  }

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array
  }

  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li
        className={(active ? "bg-white" : "bg-none") + " mx-1 sm:w-2 sm:h-2 w-1 h-1 border rounded-full"}
        onClick={() => onClick()}
      >
        <div className=""></div>
      </li>
    );
  };

  const responsive = {
    superLargeDesktop2: {
      breakpoint: { max: 10000, min: 1580 },
      items: 1
    },
    superLargeDesktop: {
      breakpoint: { max: 1580, min: 1280 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1280, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 786, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/home`).then(x => { { setData(x); setShuffledTopSongs(shuffleArray([...x.songs.filter(x => x.rank > 1 && x.rank < 5)])); setShuffledTopSongs2(shuffleArray([...x.songs.filter(x => x.rank > 51 && x.rank < 55)])); setShuffled1Songs(shuffleArray([...x.songs.filter(x => x.rank == 1)])) } }) }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/charts`).then(x => { setCharts(x.charts) }) }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/genre/preview`).then(x => { setGenrePreview(x) }).then(console.log("genrePreview")) }, [])
  useEffect(() => {
    if (localStorage.getItem('token')) {
      api(`${process.env.REACT_APP_API_URL}/user/network`).then(x => { { setNetworkSongs(x); } })
      api(`${process.env.REACT_APP_API_URL}/user/welcome-message`).then(x => { setWelcomeMessage(x.message.welcome); setWelcomeFlag(x.message.code); setWelcomeCountry(x.message.country) })
      api(`${process.env.REACT_APP_API_URL}/user/get-requests-home`).then(x => { setRequests(x) })
    }
    else
      setLoggedIn(false);
  }, [])

  const getYoutubeVideoId = (url) => {
    return url?.split('v=')[1]
  }

  function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }


  return (<div className="flex flex-col h-full">
    <div className="flex flex-col w-full max-w-6xl mx-auto">
      <div className="flex items-center">
        <Flag country={welcomeFlag} size={50} className={`${welcomeFlag ?? 'hidden'} pr-4 inline-block`}></Flag>
        <div className="my-8 text-4xl font-black text-white">{`${welcomeMessage ?? "Welcome"}`}</div>
      </div>

      {!ignoredAbout && <>

        <div
            className="hidden relative p-5 my-5 text-center text-white border rounded-xl bg-gradient-to-br from-neutral-600 to-neutral-700">
          <div onClick={() => clickIgnoreAbout() /* USE THIS AREA FOR HOME PAGE POPUPS (remove hidden in first div)*/}
               className="absolute cursor-pointer right-5"><XMarkIcon
              className="w-8 h-8 transform hover:text-neutral-800"></XMarkIcon></div>
          <div className="mb-5 text-2xl font-bold">About KaZang</div>
          <p className="my-1 mt-5 font-medium text-light">Welcome to KaZang – a music discovery platform that helps
            people find music they love in languages they don’t speak.</p>
          <p className="my-1 font-medium text-light">KaZang breaks barriers to build bridges. We cross frontiers to open
            minds. We look beyond the horizon to discover ourselves. We explore the world through music.</p>
          <div className="text-purple-300 underline cursor-pointer" onClick={() => navigate('/about')}>Read More...
          </div>
        </div>

      </>}
      {requests?.length > 0 && <div className="my-2 mt-5 text-xl font-medium text-white">Friend Requests</div>}
      {requests?.map(x => <div onClick={() => navigate('/profile/' + x.requester._id)}
                               className="flex items-center p-2 space-x-2 cursor-pointer hover:bg-neutral-100/10 ">
        {x.requester.profilePicture && <div>
          <ImageViewer className="w-12 h-12 rounded-full" image={x.requester.profilePicture}></ImageViewer>
        </div>}
        <div className="mr-5">
          <div className="text-xl font-semibold text-white">{x.requester.name}</div>
          <div className="text-sm font-light text-light">{x.requester.bio}</div>

        </div>
        <div className="text-light">

          <div className="flex space-x-4">
            <div className="w-10 h-10 p-1 bg-green-700 rounded-full shadow" onClick={(e) => {
              e.stopPropagation();
              acceptRequest(x._id)
            }}>
              <CheckIcon className="inline hover:opacity-70"></CheckIcon>

            </div>
            <div className="w-10 h-10 p-1 bg-red-700 rounded-full shadow" onClick={(e) => {
              e.stopPropagation();
              rejectRequest(x._id)
            }}>
              <XMarkIcon className="inline hover:opacity-70"></XMarkIcon>

            </div>

          </div>

        </div>

      </div>)}

      {/* Old number one song banner */}
      {/* <div className="flex">
        <div className="my-2 mt-5 text-xl font-medium text-white">Number 1 Songs</div>
        <div className="relative">
          <HelpPopup text="Number 1 Songs features the songs that are Number 1 (by views) on YouTube’s charts for each of the 25 countries featured on KaZang."></HelpPopup>

        </div>
      </div> */}

      <div className="relative flex w-full overflow-hidden">
        <div className="w-full max-w-3xl max-h-2000 mx-auto mb-2">
          {data?.songs?.length > 0 && <Carousel customDot={<CustomDot/>} infinite={true} itemClass="pb-8"
                                                autoPlay={true}
                                                removeArrowOnDeviceType={['superLargeDesktop2', 'superLargeDesktop', 'desktop', 'tablet', 'mobile']}
                                                autoPlaySpeed={4000} showDots={true} responsive={responsive}
                                                containerClass="">

            {shuffled1Songs.map((x, i) => <div onClick={(e) => {
              e.stopPropagation();
              player.setSong({...x, id: getYoutubeVideoId(x.url)});
              player.ChangeMode("Random", "");
            }}
                                               className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none group bg-neutral-700 hover:bg-neutral-600">
              <div onClick={(e) => {
                e.stopPropagation();
                player.setSong({...x, id: getYoutubeVideoId(x.url)})
              }}
                   className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
                <PlayIcon className="w-8 text-white"/>
              </div>

              <div className="relative flex">
                <img className="object-cover w-full mx-auto rounded h-96"
                     src={`https://i.ytimg.com/vi_webp/${getYoutubeVideoId(x.url)}/sddefault.webp`}></img>

                <div
                    className="absolute inset-0 p-2 pointer-events-none select-none bg-gradient-to-r from-neutral-900/60 to-neutral-50/0">
                  <div className="flex flex-col justify-between h-full p-2">

                    <div className="text-4xl font-semibold tracking-wider text-light">{x.chart} No. 1</div>
                    <div className="pb-5">
                      <div className='mt-1 text-2xl font-semibold text-white truncate'>{x.name}</div>
                      <div className="text-lg font-medium truncate text-light">{x.artist}</div>
                    </div>

                  </div>

                </div>
              </div>

            </div>)}

          </Carousel>}
        </div>
      </div>


      <div className="flex">
        <div className="my-2 mt-5 text-xl font-medium text-white">Trending Songs</div>
        <div className="relative">
          <HelpPopup
              text="Trending Songs showcase songs selected from the YouTube charts outside of the top songs chart"></HelpPopup>

        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">

        {shuffledTopSongs2?.filter((x, i) => i < 6).map((x, i) => <div onClick={(e) => {
          e.stopPropagation();
          player.setSong({...x, id: getYoutubeVideoId(x.url)});
          player.ChangeMode("Random", "");
        }}
                                                                       className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600">
          <div onClick={(e) => {
            e.stopPropagation();
            player.setSong({...x, id: getYoutubeVideoId(x.url)});
            player.ChangeMode("Random", "");
          }}
               className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
            <PlayIcon className="w-8 text-white"/>
          </div>
          <div className="p-2 text-lg font-semibold tracking-wider text-light">{x.chart}</div>
          <div className="flex p-2">
            <img className="object-cover rounded"
                 src={`https://img.youtube.com/vi/${getYoutubeVideoId(x.url)}/mqdefault.jpg`}></img>
          </div>
          <div className="p-2">
            <div className='mt-1 text-base font-semibold text-white truncate'>{x.name}</div>
            <div className="text-xs font-medium truncate text-light">{x.artist}</div>
          </div>
        </div>)}
      </div>

      {networkSongs?.filter(x => data?.songs?.find(y => y._id == x.song)).filter((x, i) => data?.songs?.find(y => y._id == x.song).rank < 101).length > 0 &&
          <div className="my-2 mt-5 text-xl font-medium text-white">Popular In Your Network</div>}
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        {networkSongs?.filter(x => data?.songs?.find(y => y._id == x.song)).filter((x, i) => data?.songs?.find(y => y._id == x.song).rank < 101).filter((x, i) => i < 10).map(x =>
            <div>
              <div onClick={(e) => {
                e.stopPropagation();
                player.setSong({...x, id: getYoutubeVideoId(x.url)})
              }}
                   className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600">
                <div onClick={(e) => {
                  e.stopPropagation();
                  player.setSong({
                    ...data?.songs?.find(y => y._id == x.song),
                    id: getYoutubeVideoId(data?.songs?.find(y => y._id == x.song)?.url)
                  })
                }}
                     className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
                  <PlayIcon className="w-8 text-white"/>
                </div>
                <div
                    className="p-2 text-lg font-semibold tracking-wider text-light">{data?.songs?.find(y => y._id == x.song)?.chart}</div>
                <div className="flex p-2">
                  <img className="object-cover rounded"
                       src={`https://img.youtube.com/vi/${getYoutubeVideoId(data?.songs?.find(y => y._id == x.song)?.url)}/mqdefault.jpg`}></img>
                </div>
                <div className="p-2">

                  <div
                      className='mt-1 text-base font-semibold text-white truncate'>{data?.songs?.find(y => y._id == x.song)?.name}</div>
                  <div
                      className="text-xs font-medium truncate text-light">{data?.songs?.find(y => y._id == x.song)?.artist}</div>
                </div>
              </div>
            </div>)}
      </div>

      {genres.map(x => <div>

        <div className="my-2 mt-5 text-xl font-medium text-white cursor-pointer hover:underline"
             onClick={() => navigate('/genre/' + x.toLowerCase())}>{x.toUpperCase()}
          <b className="ml-3 px-1 pt-1 pb-1 text-sm font-bold text-white bg-purple-500 rounded">Show More</b>
        </div>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">

          {genrePreview && shuffleArray(genrePreview.songs[x]).slice(0, 6).map((y, i) =>
              <div onClick={(e) => {
                e.stopPropagation();
                player.setSong({...y, id: getYoutubeVideoId(y.url)});
                player.ChangeMode("Genre", x);
              }}
                   className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600">
                <div onClick={(e) => {
                  e.stopPropagation();
                  player.setSong({...y, id: getYoutubeVideoId(y.url)})
                }}
                     className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
                  <PlayIcon className="w-8 text-white"/>
                </div>
                <div className="p-2 text-lg font-semibold tracking-wider text-light"> {y.chart}
                </div>

                <div className="flex p-2">
                  <img className="object-cover rounded"
                       src={`https://img.youtube.com/vi/${getYoutubeVideoId(y.url)}/mqdefault.jpg`}></img>
                </div>
                <div className="p-2">


                  <div className='mt-1 text-base font-semibold text-white truncate'>{y.name}</div>
                  <div className="text-xs font-medium truncate text-light">{y.artist}</div>

                </div>
              </div>)}
        </div>
      </div>)}

      <div className="flex">
        <div className="my-2 mt-5 text-xl font-medium text-white">Popular Songs</div>
        <div className="relative">
          <HelpPopup
              text="Popular Songs are drawn from YouTube’s “Top Songs” chart. This chart ranks songs by the number of YouTube views. The count combines views for the official music video, for user-generated content using the original song, and videos featuring the song and its lyrics."></HelpPopup>

        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">

        {shuffledTopSongs?.filter((x, i) => i < 6).map((x, i) => <div onClick={(e) => {
          e.stopPropagation();
          player.setSong({...x, id: getYoutubeVideoId(x.url)})
        }}
                                                                      className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600">
          <div onClick={(e) => {
            e.stopPropagation();
            player.setSong({...x, id: getYoutubeVideoId(x.url)});
            player.ChangeMode("Random", "");
          }}
               className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
            <PlayIcon className="w-8 text-white"/>
          </div>
          <div className="p-2 text-lg font-semibold tracking-wider text-light">{x.chart}</div>
          <div className="flex p-2">
            <img className="object-cover rounded"
                 src={`https://img.youtube.com/vi/${getYoutubeVideoId(x.url)}/mqdefault.jpg`}></img>
          </div>
          <div className="p-2">

            <div className='mt-1 text-base font-semibold text-white truncate'>{x.name}</div>
            <div className="text-xs font-medium truncate text-light">{x.artist}</div>
          </div>
        </div>)}
      </div>

      {charts?.filter(x => x != "Other").map(x => <div>

        <div className="my-2 mt-5 text-xl font-medium text-white cursor-pointer hover:underline"
             onClick={() => navigate('/chart/' + x.toLowerCase())}>{x} Top 10
        </div>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">

          {data?.songs?.filter(y => y.chart == x).sort((a, b) => a.rank - b.rank).filter((x, i) => i < 10).map((x, i) =>
              <div onClick={(e) => {
                e.stopPropagation();
                player.setSong({...x, id: getYoutubeVideoId(x.url)});
                player.ChangeMode("Chart", x.chart);
              }}
                   className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600">
                <div onClick={(e) => {
                  e.stopPropagation();
                  player.setSong({...x, id: getYoutubeVideoId(x.url)})
                }}
                     className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
                  <PlayIcon className="w-8 text-white"/>
                </div>
                <div className="p-2 text-lg font-semibold tracking-wider text-light"><b
                    className="px-3 pt-1 pb-1 text-xl font-bold text-white bg-purple-500 rounded">{i + 1}</b> {x.chart}
                </div>

                <div className="flex p-2">
                  <img className="object-cover rounded"
                       src={`https://img.youtube.com/vi/${getYoutubeVideoId(x.url)}/mqdefault.jpg`}></img>
                </div>
                <div className="p-2">


                  <div className='mt-1 text-base font-semibold text-white truncate'>{x.name}</div>
                  <div className="text-xs font-medium truncate text-light">{x.artist}</div>

                </div>
              </div>)}
        </div>
      </div>)}
      <div className="my-10"></div>
    </div>
  </div>)
}