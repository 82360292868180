import axios from 'axios';
import {toast} from "react-toastify";
export const api = (endpoint, data) => {
    if (data) {
        return fetch(endpoint, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 401) {
                window.location.replace('/login');
            } else if (res.status === 402) {
                toast.error("Subscription Required");
            } else if (res.status === 400) {
                return {err: res.status};
            }

            return res.json()
        })
    }
    else {
        return fetch(endpoint, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            })
        }).then(res => {
            if (res.status === 401) {
                window.location.replace('/login');
            } else if (res.status === 402) {
                toast.error("Subscription Required");
            } else if (res.status === 400) {
                return {err: res.status};
            }
            return res.json()
        })
    }
}

export const api_file = (endpoint, data) => {
    return fetch(endpoint, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: data
    }).then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
}

export const api_update = (endpoint, data) => {
    return fetch(endpoint, {
        method: 'PUT',
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(data)
    }).then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
}

export const api_delete = (endpoint) => {
    return fetch(endpoint, {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
    }).then(res => { if (res.status === 401) window.location.replace('/login'); return res })
}