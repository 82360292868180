import { PlayIcon } from '@heroicons/react/20/solid'
import { CheckIcon, HomeIcon, MapPinIcon, UserMinusIcon, UserPlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { usePlayer } from './App'
import { api } from './api.service'
import Button from './components/button'
import FriendCard from './components/friend-card'
import ImageViewer from './components/image-viewer'
import Loading from './components/loading'

export default function Profile() {

  const [data, setData] = useState()
  const [userData, setUserData] = useState()
  const [ownUserData, setOwnUserData] = useState()
  const params = useParams();
  const navigate = useNavigate();
  const player = usePlayer();
  const [requests, setRequests] = useState()
  const getYoutubeVideoId = (url) => {
    if (!url) return ""
    return url.split('v=')[1]
  }

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/user/get-requests`).then(x => { setRequests(x) })
    if (params.id == "me") {
      api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setUserData(x) })
    }
    else {
      api(`${process.env.REACT_APP_API_URL}/user/profile/${params.id}`).then(x => { setUserData(x) })
    }
  }, [params.id])

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setOwnUserData(x) }) }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public`).then(x => { setData(x); }) }, [])

  const removeContact = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/remove-friend/${id}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setOwnUserData(x) })
    })
  }

  const sendRequest = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/send-request/${id}`, { message: "" }).then(x => {
      api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setOwnUserData(x) })
      api(`${process.env.REACT_APP_API_URL}/user/get-requests`).then(x => { setRequests(x) })
    })
  }

  const acceptRequest = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/accept-request/${id}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setOwnUserData(x) })
      api(`${process.env.REACT_APP_API_URL}/user/get-requests`).then(x => { setRequests(x) })
    })
  }

  const rejectRequest = (id) => {
    api(`${process.env.REACT_APP_API_URL}/user/reject-request/${id}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setOwnUserData(x) })
      api(`${process.env.REACT_APP_API_URL}/user/get-requests`).then(x => { setRequests(x) })
    })
  }

  if (!data) return <Loading></Loading>

  return (<div className="flex flex-col h-full max-w-6xl mx-auto">
    <ToastContainer theme="dark" pauseOnHover={false}></ToastContainer>

    <div className="flex items-center justify-between py-5 my-5">
      <div className="flex flex-col space-x-5 sm:items-center sm:flex-row">

        <div className="flex space-x-5">
          {userData?.profilePicture && <div>
            <ImageViewer className="w-24 h-24 rounded-full shrink-0" image={userData.profilePicture}></ImageViewer>
          </div>}
          <div>
            <div className="my-2 text-4xl font-black text-white">{userData?.name}</div>
            <div className="my-2 text-lg font-light text-light">{userData?.bio}</div>
          </div>

        </div>
        <div className="flex flex-col justify-end ">
          {userData?.from && <div className="my-2 text-lg font-light text-light"><MapPinIcon className="inline w-6 mr-3 text-light"></MapPinIcon> from <b>{userData?.from}</b></div>}
          {userData?.livingIn && <div className="my-2 text-lg font-light text-light"><HomeIcon className="inline w-6 mr-3 text-light"></HomeIcon> living in <b>{userData?.livingIn}</b></div>}
        </div>

      </div>
      <div className="flex items-center my-auto ml-auto space-x-4 text-white">

        {params.id != "me" && <>

          {/* Is in contacts */}
          {ownUserData?.contacts?.find(y => userData._id == y._id) && <>
            <UserMinusIcon title="Remove Friend" className="inline w-12 hover:opacity-70" onClick={(e) => { e.stopPropagation(); removeContact(userData._id) }}></UserMinusIcon>
          </>}

          {/* Not in contacts */}
          {!ownUserData?.contacts?.find(y => userData._id == y._id) && <>
            {/* User has sent us a request */}
            {requests?.requests?.find(y => y.requester + '' == userData._id + '') && <div className="flex items-center space-x-4">
              <div className="text-lg font-medium">Reply To Friend Request</div>
              <div onClick={(e) => { e.stopPropagation(); acceptRequest(requests?.requests?.find(y => y.requester + '' == userData._id + '')._id) }}>
                <CheckIcon className="inline w-12 hover:opacity-70" ></CheckIcon>

              </div>
              <div onClick={(e) => { e.stopPropagation(); rejectRequest(requests?.requests?.find(y => y.requester + '' == userData._id + '')._id) }}>
                <XMarkIcon className="inline w-12 hover:opacity-70" ></XMarkIcon>

              </div>

            </div>}

            {/* User has not sent us a request */}
            {!requests?.requests?.find(y => y.requester + '' == userData._id + '') && <>
              {requests?.sentRequests?.find(y => y.requestee + '' == userData._id + '') && <>
                Request Pending
              </>}
              {!requests?.sentRequests?.find(y => y.requestee + '' == userData._id + '') && <>
                <UserPlusIcon className="inline w-12 hover:opacity-70" onClick={(e) => { e.stopPropagation(); sendRequest(userData._id) }}></UserPlusIcon>
              </>}
            </>}

          </>}

        </>}
        {params.id == "me" && <>


          <Button text="Edit Profile" onClick={() => navigate('/settings')} />


        </>}
      </div>
    </div>
    <div className="my-2 mt-5 text-xl font-medium text-white">Liked Songs</div>
    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">

      {data?.songs?.filter(x => userData?.likedSongs?.find(y => y.song == x._id)).map((x, i) => <div onClick={() => navigate('/song/' + x._id)} className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600">
        <div onClick={(e) => { e.stopPropagation(); player.setSong({ ...x, id: getYoutubeVideoId(x.url) }) }} className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
          <PlayIcon className="w-8 text-white" />
        </div>
        <div className="p-2 text-lg font-semibold tracking-wider text-light">{x.chart}</div>
        <div className="flex p-2">
          <img className="object-cover rounded" src={`https://img.youtube.com/vi/${getYoutubeVideoId(x.url)}/mqdefault.jpg`}></img>
        </div>
        <div className="p-2">
          <div className='mt-1 text-base font-semibold text-white truncate'>{x.name}</div>
          <div className="text-xs font-medium line-clamp-2 text-light">{x.artist}</div>
        </div>
      </div>)}
    </div><div className="my-2 mt-5 text-xl font-medium text-white">Friends</div>
    <div className="grid grid-cols-1 gap-4 pb-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {userData?.contacts?.map(x => <FriendCard x={x} ownUserData={ownUserData} requests={requests} removeContact={removeContact} sendRequest={sendRequest} acceptRequest={acceptRequest} rejectRequest={rejectRequest} />)}
    </div>
  </div>)
}