import CheckboxField from "./fields/checkbox-field";
import ImageUpload from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";

import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";

export default function ProfileForm(props) {
    const params = useParams();
    const navigate = useNavigate();

    return <div className="max-w-6xl mx-auto">
        <div className="mt-5 mb-3 text-5xl font-black text-white">Edit Profile</div>

        <FormWrapper url="user/profile" noStyle={true} noId {...props} callback={() => navigate('/profile/me')}>
            {(values, setValues) =>
                <div className="flex flex-col space-y-2 text-white">
                    <div className="grid grid-cols-2 gap-2">
                        <ImageUpload label="Profile Picture" value={values.profilePicture} onChange={(val) => setValues({ ...values, profilePicture: val })}></ImageUpload>


                        <CheckboxField label="Make Profile Private" value={values.hiddenProfile} onChange={(val) => setValues({ ...values, hiddenProfile: val })}></CheckboxField>


                    </div>

                    <TextField className="col-span-2" label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                    <TextAreaField label="Bio" value={values.bio} onChange={(val) => setValues({ ...values, bio: val })}></TextAreaField>

                    <div className="grid grid-cols-2 gap-2">
                        <TextField label="From" value={values.from} onChange={(val) => setValues({ ...values, from: val })}></TextField>
                        <TextField label="Living In" value={values.livingIn} onChange={(val) => setValues({ ...values, livingIn: val })}></TextField>
                    </div>
                    <div className="text-xs text-neutral-500">Your location information is optional and will be displayed on your public profile.</div>
                    <div className="grid grid-cols-2 gap-2">
                        <SelectField options={[{ text: '18-25', value: '18-25' }, { text: '26-30', value: '26-30' }, { text: '31-35', value: '31-35' }, { text: '36-40', value: '36-40' }, { text: '40-60', value: '40-60' }, { text: '60+', value: '60+' }]} label="Age" value={values.age} onChange={(val) => setValues({ ...values, age: val })}></SelectField>
                        <SelectField options={[{ text: 'Female', value: 'Female' }, { text: 'Male', value: 'Male' }, { text: 'Prefer not to say', value: 'Prefer not to say' }, { text: 'Other', value: 'Other' },]} label="Gender" value={values.gender} onChange={(val) => setValues({ ...values, gender: val })}></SelectField>
                    </div>
                    <div className="text-xs text-neutral-500">Your age and gender information is private and won't be displayed publicly.</div>

                    <div className="text-xs text-neutral-500">Check this box to hide your profile from searches.</div>

                </div>
            }
        </FormWrapper>
    </div>
}