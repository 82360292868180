import { PauseIcon, PlayIcon, HeartIcon as SolidHeartIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, DocumentDuplicateIcon, HeartIcon, ShareIcon, SpeakerWaveIcon, SpeakerXMarkIcon, TvIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YouTube from 'react-youtube';
import { usePlayer } from './App';
import { api } from './api.service';
import FormModal from './forms/form-modal';
import logo from './logo-dark.png';
import { Tooltip } from 'react-tooltip'

export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState();
  const [duration, setDuration] = useState();
  const player = usePlayer();
  const [playerRef, setPlayerRef] = useState()
  const hiddenPlayRef = useRef();
  const [userData, setUserData] = useState()
  const [charts, setCharts] = useState([])
  const [genres, setGenres] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [debugOpen, setDebugOpen] = useState(false)
  const [debug, setDebug] = useState([])
  const [chartsOpen, setChartsOpen] = useState(false)
  const [addToPlaylistOpen, setAddToPlaylistOpen] = useState(false)
  const [playlists, setPlaylists] = useState([])
  const [volume, setVolume] = useState();
  const [videoLoading, setVideoLoading] = useState(0);


  async function onPlayerReady(event) {
    setPlayerRef(event.target)

    //const timeout = setTimeout(() => event.target.playVideo(), 1000)

    //event.target.mute()
  }

  function onPlayerEnd(event) {
    nextSong()
  }

  const nextSong = () => {
    player.nextSong();
  }

  const previousSong = () => {
    player.previous();
  }

  const likeSong = (id, liked) => {
    api(`${process.env.REACT_APP_API_URL}/user/like/${id}/${liked}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setUserData(x) });
    })
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setUserData(x) });
      api(`${process.env.REACT_APP_API_URL}/user/playlists`).then(x => { setPlaylists([...x.myPlaylists, ...x.memberPlaylists]) });
    }
  }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/charts`).then(x => { setCharts(x.charts) }) }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/public/genre`).then(x => { setGenres(x.genres) }) }, [])

  useEffect(() => {
    playerRef?.setVolume(volume)
  }, [volume])

  useEffect(() => {
    playerRef?.loadVideoById(player?.song?.id)
  }, [player.song])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(playerRef?.getCurrentTime())
    }, 100);

    //const timeout = setTimeout(() => playerRef?.unMute(), 500)

    return () => { clearInterval(interval); };
  }, [playerRef])

  useEffect(() => {
    const videoOpen = sessionStorage.getItem('videoOpen');
    if(player.isPlaying && videoOpen === null || videoOpen === 'true'){
      setVideoOpen(true);
    }
  }, [player.isPlaying])

  /*
  useEffect(() => {
    if (player.isPlaying) setVideoOpen(true)
  }, [player.isPlaying])
*/
  const togglePlayer = () => {
    if (!player.isPlayer && !player.song) {
      nextSong();
      return
    }
    if (player.isPlaying) {
      playerRef.pauseVideo()
      player.toggle()
    }
    else {
      playerRef?.playVideo()
      player.toggle()
    }
  }
  const formatSeconds = (seconds) => {
    if (!seconds) return '0:00'
    let output = ''
    let minutes = Math.floor(seconds / 60);
    output += minutes + ':';
    let secs = Math.floor(seconds - minutes * 60)
    if (secs > 9) output += secs;
    else output += '0' + secs;
    return output;
  }
  const addToPlaylist = (playlistId, songId) => {
    api(`${process.env.REACT_APP_API_URL}/user/playlist/add-song/${playlistId}/${songId}`).then(x => { setUserData(x) }).then(x => {toast.success("Song added to playlist")})
  }

  return (
    <>
      {/* {(muted || playerRef?.isMuted()) && <div onClick={() => { setMuted(false); playerRef?.unMute() }}><MusicalNoteIcon className="fixed z-40 w-8 p-2 text-black bg-white rounded-full shadow top-2 right-2"></MusicalNoteIcon></div>} */}
      <ToastContainer theme="dark" pauseOnHover={false}></ToastContainer>
      <FormModal open={shareOpen} setOpen={setShareOpen}>
        <div className="p-7 bg-slate-700">
          <div className="text-3xl font-black text-white">Share</div>
          <div className="mt-3 text-lg font-medium text-light">Share this song with others by sending them the URL! You can copy the link to send however you like, or click below to send on Whatsapp, Telegram, or Facebook!</div>
          <div className="grid max-w-xl grid-cols-3 gap-4 mx-auto mt-10 place-items-center">
            <FacebookShareButton quote={"Check out this song on KaZang!"} url={`${process.env.REACT_APP_URL}/song/${player.song?._id}`}><FacebookIcon className="mx-1 rounded"></FacebookIcon></FacebookShareButton>
            <WhatsappShareButton title={"Check out this song on KaZang!"} url={`${process.env.REACT_APP_URL}/song/${player.song?._id}`}><WhatsappIcon className="mx-1 rounded"></WhatsappIcon></WhatsappShareButton>
            <TelegramShareButton title={"Check out this song on KaZang!"} url={`${process.env.REACT_APP_URL}/song/${player.song?._id}`}><TelegramIcon className="mx-1 rounded"></TelegramIcon></TelegramShareButton>
          </div>
          <CopyToClipboard onCopy={() => toast("Copied")} text={`${process.env.REACT_APP_URL}/song/${player.song?._id}`}>
            <div className="flex justify-center mt-10 space-x-4 text-center cursor-pointer hover:text-blue-600 text-light">
              <DocumentDuplicateIcon className="w-8 " />
              <div className="text-xl ">Copy URL</div>
            </div>
          </CopyToClipboard>
        </div>

      </FormModal>
      <FormModal open={debugOpen} setOpen={setDebugOpen}>
        <div className="p-2 bg-white">
          {debug.map(x => <div>{x}</div>)}
          <div onClick={() => setDebug(false)}>close</div>
        </div>
      </FormModal>
      <div className="relative flex flex-col min-h-screen pt-10 bg-purple-800 lg:h-screen lg:pt-0 ">
        {!sidebarOpen && <div className='absolute lg:hidden left-3 top-2' onClick={() => setSidebarOpen(true)}>
          <Bars3Icon className="w-8 text-white"></Bars3Icon>
        </div>}
        {sidebarOpen && <div className='lg:hidden absolute left-[85%] top-2' onClick={() => setSidebarOpen(false)}>
          <XMarkIcon className="w-8 text-white"></XMarkIcon>
        </div>}
        <div className="flex flex-1 pb-24 lg:overflow-y-scroll lg:pb-0 ">
          <div
              className={`${sidebarOpen ? 'flex' : 'lg:flex hidden'} w-64 lg:overflow-y-scroll lg:bg-gradient-to-b from-neutral-700 to-dark rounded mt-3 ml-3 flex-col pt-8 pr-1  px-5 pb-3 shadow`}>
            <div className="w-full"></div>
            <img src={logo} className="mx-auto w-44"></img>
            <div className="flex flex-col flex-1 text-left">
              <div onClick={() => {
                navigate('/');
                setSidebarOpen(false)
              }}
                   className={`${location.pathname == '/' ? 'bg-purple-500' : 'hover:bg-neutral-600'} font-medium text-white p-2  rounded cursor-pointer select-none mt-5 text-lg`}>Home
              </div>
              <div onClick={() => {
                navigate('/profile/me');
                setSidebarOpen(false)
              }}
                   className={`${location.pathname == '/profile/me' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>My
                Profile
              </div>
              <div onClick={() => {
                navigate('/library');
                setSidebarOpen(false)
              }}
                   className={`${location.pathname == '/library' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Library
              </div>
              <div onClick={() => {
                navigate('/add-song');
                setSidebarOpen(false)
              }}
                   className={`${location.pathname == '/add-song' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Add
                a Song
              </div>
              <div onClick={() => {
                navigate('/search');
                setSidebarOpen(false)
              }}
                   className={`${location.pathname == '/search' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Search
              </div>
              <div onClick={() => {
                navigate('/friends');
                setSidebarOpen(false)
              }}
                   className={`${location.pathname == '/friends' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Friends
              </div>
              <div onClick={() => {
                navigate('/playlists');
                setSidebarOpen(false)
              }}
                   className={`${location.pathname == '/playlists' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Playlists
              </div>
              <div onClick={() => {
                player.ChangeMode("Random", "");
                nextSong();
                setSidebarOpen(false)
              }}
                   className={`${location.pathname == '/playlists' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Random
              </div>

              <div className={`font-medium text-white p-2 rounded  mt-2 select-none flex justify-between text-lg`}>
                <div>Songs by Genre</div>
              </div>
              <div className="ml-4 border-l border-neutral-600">
                {genres.map(x =>
                    <div key={x} onClick={() => {
                      navigate('/genre/' + x.toLowerCase());
                      setSidebarOpen(false);
                      player.ChangeMode("Genre", x.toLowerCase());
                    }}
                         className={/* TODO -- send path to genre page*/ `${location.pathname == ('/genre/' + x.toLowerCase()) ? 'bg-purple-500' : 'hover:bg-neutral-600'}  text-xs font-medium  text-white p-2  rounded cursor-pointer select-none`}>{x}</div>)}

              </div>
              <div className={`font-medium text-white p-2 rounded  mt-2 select-none flex justify-between text-lg`}>
                <div>Charts by Country</div>
              </div>
              <div className="ml-4 border-l border-neutral-600">
                {charts.filter(x => x === "Global").map(x => <div key={x} onClick={() => {
                  navigate('/chart/' + x.toLowerCase());
                  setSidebarOpen(false);
                  player.ChangeMode("Chart", x);
                }}
                                                                  className={`${location.pathname == ('/chart/' + x.toLowerCase()) ? 'bg-purple-500' : 'hover:bg-neutral-600'}  text-base font-semibold  text-white p-2  rounded cursor-pointer select-none`}>{x}</div>)}

                {charts.filter(x => x !== "Global").sort((a, b) => (a < b ? 1 : -1)).sort((a, b) => a == "Other" ? 1 : -1).map(x =>
                    <div key={x} onClick={() => {
                      navigate('/chart/' + x.toLowerCase());
                      setSidebarOpen(false);
                      player.ChangeMode("Chart", x);
                    }}
                         className={`${location.pathname == ('/chart/' + x.toLowerCase()) ? 'bg-purple-500' : 'hover:bg-neutral-600'}  text-xs font-medium  text-white p-2  rounded cursor-pointer select-none`}>{x}</div>)}

              </div>

              <div className="flex-1">
              </div>
              <div className="block sm:hidden">
                <div className="p-2 mt-8 text-xs font-medium rounded text-light"></div>
                <div onClick={() => {
                  navigate('/about');
                  setSidebarOpen(false)
                }}
                     className={`${location.pathname == '/about' ? 'bg-purple-500' : 'hover:bg-neutral-600'} font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>About
                  KaZang
                </div>
                <div onClick={() => {
                  navigate('/faqs');
                  setSidebarOpen(false)
                }}
                     className={`${location.pathname == '/faqs' ? 'bg-purple-500' : 'hover:bg-neutral-600'} font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>FAQs
                </div>
                {userData && (
                    <div onClick={() => {
                      navigate('/settings');
                      setSidebarOpen(false)
                    }}
                         className={`${location.pathname == '/settings' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Edit
                      Profile
                    </div>
                )}
                {userData && (
                    <div onClick={() => navigate('/logout')}
                         className={`${location.pathname == '/logout' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Logout
                    </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="hidden pt-3 pl-3 sm:block">
              <div className="flex justify-end w-full p-5 space-x-4 rounded bg-neutral-700">
                {userData && (
                    <div onClick={() => {
                      navigate('/admin');
                      setSidebarOpen(false)
                    }}
                         className={`${userData?.isAdmin ?? 'hidden'} ${location.pathname == '/admin' ? 'bg-purple-500' : 'hover:bg-neutral-600'} font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Admin
                      Console
                    </div>
                )}
                <div onClick={() => {
                  navigate('/about');
                  setSidebarOpen(false)
                }}
                     className={`${location.pathname == '/about' ? 'bg-purple-500' : 'hover:bg-neutral-600'} font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>About
                  KaZang
                </div>
                <div onClick={() => {
                  navigate('/faqs');
                  setSidebarOpen(false)
                }}
                     className={`${location.pathname == '/faqs' ? 'bg-purple-500' : 'hover:bg-neutral-600'} font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>FAQs
                </div>
                {!userData && (
                    <div className="p-3 px-5 font-semibold text-black bg-white rounded-full cursor-pointer"
                         onClick={() => navigate('/login')}>Sign In
                    </div>
                )}
                {userData && (
                    <div onClick={() => {
                      navigate('/settings');
                      setSidebarOpen(false)
                    }}
                         className={`${location.pathname == '/settings' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Edit
                      Profile
                    </div>
                )}
                {userData && (
                    <div onClick={() => navigate('/logout')}
                         className={`${location.pathname == '/logout' ? 'bg-purple-500' : 'hover:bg-neutral-600'}  font-medium text-white p-2  rounded cursor-pointer select-none text-lg`}>Logout
                    </div>
                )}
              </div>
            </div>

            <div
                className={`${!sidebarOpen ? '' : 'lg:block hidden'} lg:pb-0 pb-20 flex-1 lg:overflow-y-scroll lg:bg-gradient-to-b from-neutral-700 to-dark rounded lg:mt-3 lg:ml-3 shadow lg:px-5 px-2`}>
              <Outlet></Outlet>
            </div>
          </div>


        </div>
        <div>
          <div className={`${!videoOpen ? 'hidden' : 'h-5 px-5 bg-purple-800 lg:flex justify-evenly'}`}></div>
            <YouTube
                id={'video'}
                className={`${!videoOpen ? 'hidden' : 'lg:h-96 h-64 mx-auto w-full lg:relative fixed bottom-0'} `}
                iframeClassName={'w-full h-full'}
                title={''}
                opts={{
                  playerVars: {
                    controls: 0,
                    disablekb: 1,
                    fs: 0,
                    modestbranding: 1,
                    //autoplay: 1
                  },
                }}
                onReady={onPlayerReady}
                onPlay={() => {
                  player.startPlaying();
                }}
                onPause={() => {
                  player.stopPlaying();
                }}
                onEnd={onPlayerEnd}
                onError={(e) => {
                  console.log(e)
                }}
                onStateChange={(e) => {
                  setVideoLoading(e.data);
                  if (e.data == 5) player.stopPlaying()
                }}
                onPlaybackRateChange={() => {
                }}
                onPlaybackQualityChange={() => {
                }}
            />
        </div>
        <aside className="hidden h-24 px-5 bg-purple-800 lg:flex justify-evenly">
          <div className="flex flex-1">
            <div className="my-auto">
              <div className="text-xs font-semibold text-light">{player.song?.chart}</div>
              <div onClick={() => {
                navigate('/song/' + player.song?._id)
              }}
                   className="font-medium text-white text-md text-decoration: underline cursor-pointer">{player.song?.name}</div>
              <div className="text-xs text-light">{player.song?.artist}</div>
            </div>
          </div>
          <div className="flex items-center justify-center flex-1">
            <div className="flex flex-col w-full select-none">

              <div className="flex items-center justify-center">
                <div onClick={() => previousSong()}
                     className="ml-auto text-white transition cursor-pointer hover:scale-105"><ChevronDoubleLeftIcon
                    className="w-6"></ChevronDoubleLeftIcon></div>
                <div ref={hiddenPlayRef}
                     className="p-2 mx-10 bg-purple-500 rounded-full cursor-pointer hover:bg-purple-400"
                     onClick={() => togglePlayer()}>

                  {videoLoading == -1 && <div role="status">
                    <svg aria-hidden="true" class="w-8 h-8 text-purple-500 animate-spin dark:text-gray-600 fill-light"
                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"/>
                      <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"/>
                    </svg>
                  </div>}

                  {(videoLoading == 1 || videoLoading == 3) &&
                      <PauseIcon className="w-8 h-8 ml-px text-light"></PauseIcon>}
                  {(videoLoading == 2 || videoLoading == 5) &&
                      <PlayIcon className="w-8 h-8 ml-px text-light"></PlayIcon>}
                </div>
                <div onClick={() => nextSong()}
                     className="mr-auto text-white transition cursor-pointer hover:scale-105"><ChevronDoubleRightIcon
                    className="w-6"></ChevronDoubleRightIcon></div>
              </div>

              <div className="flex w-full mt-2 text-xs font-medium text-light">
                <div className="w-20 text-right">{formatSeconds(currentTime)}</div>
                <div
                    onMouseMove={(e) => {
                      if (e.buttons == 1) {
                        var rect = e.target.getBoundingClientRect();
                        let seconds = (e.clientX - rect.left) / rect.width * playerRef?.getDuration();
                        playerRef?.seekTo(seconds)
                        setCurrentTime(seconds)
                      }
                    }}

                    onMouseDown={(e) => {
                      if (e.buttons == 1) {
                        var rect = e.target.getBoundingClientRect();
                        let seconds = (e.clientX - rect.left) / rect.width * playerRef?.getDuration();
                        playerRef?.seekTo(seconds)
                        setCurrentTime(seconds)
                      }
                    }} className="flex-1 px-2">
                  <div className="relative">
                    <div style={{width: (currentTime / playerRef?.getDuration() * 100) + '%'}}
                         className="absolute h-2 mt-1 bg-purple-500 rounded-full pointer-events-none"></div>
                  </div>
                  <div className="w-full h-2 mt-1 rounded-full bg-neutral-700"></div>
                </div>
                <div className="w-20">{formatSeconds(playerRef?.getDuration())}</div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end flex-1 space-x-8">

            {playerRef && <div className="flex items-center space-x-4">
              {!playerRef.isMuted() && playerRef.getVolume() > 0 &&
                  <SpeakerWaveIcon onClick={() => playerRef.mute()} className="w-6 text-white"/>}
              {(playerRef.isMuted() || playerRef.getVolume() == 0) &&
                  <SpeakerXMarkIcon onClick={() => playerRef.unMute()} className="w-6 text-white"/>}
              <input className="h-1 border-none accent-purple-500" type="range" value={volume}
                     onChange={(e) => setVolume(+e.target.value)}/>
            </div>}

            <div className="relative">
              {addToPlaylistOpen && <div
                  className="absolute flex flex-col w-64 overflow-hidden text-white transition duration-300 rounded-lg shadow animate-in fade-in -right-10 bottom-5 bg-slate-800">

                {playlists?.map(x => <div onClick={() => {
                  addToPlaylist(x._id, player.song?._id);
                  setAddToPlaylistOpen(false)
                }} className="p-3 transition cursor-pointer hover:bg-slate-900">{x.name}</div>)}

                <div onClick={() => {
                  navigate('/create-playlist/' + player.song?._id);
                  setAddToPlaylistOpen(false)
                }} className="p-3 transition cursor-pointer hover:bg-slate-900">Create new playlist...
                </div>
              </div>}
            </div>


            <div data-tooltip-id="my-tooltip" data-tooltip-content="Add to playlist">
              {player.song && <XMarkIcon onClick={() => {
                if (userData) {
                  setAddToPlaylistOpen(!addToPlaylistOpen);
                } else {
                  navigate('/login');
                }
              }}
                                         className="w-8 transition rotate-45 cursor-pointer text-light hover:text-white hover:scale-110"/>}
            </div>

            <div data-tooltip-id="my-tooltip" data-tooltip-content="Show Video">
              {player.song && <TvIcon onClick={() => {
                const vo = !videoOpen;
                setVideoOpen(vo);
                sessionStorage.setItem('videoOpen', vo.toString());
              }}
                                      className="w-8 ml-auto transition cursor-pointer text-light hover:scale-110 hover:text-white"/>}
            </div>

            <div data-tooltip-id="my-tooltip" data-tooltip-content="Share">
              {player.song && <>
                <ShareIcon onClick={() => setShareOpen(true)}
                           className="w-8 ml-auto transition cursor-pointer text-light hover:scale-110 hover:text-white"/>
              </>}
            </div>

            <div data-tooltip-id="my-tooltip" data-tooltip-content="Like">
              {player.song && <>
                {!userData?.likedSongs?.find(x => x.song == player?.song?._id) &&
                    <HeartIcon onClick={() => likeSong(player?.song?._id, true)}
                               className="w-8 ml-auto transition cursor-pointer text-light hover:scale-110 hover:text-white"></HeartIcon>}
                {userData?.likedSongs?.find(x => x.song == player?.song?._id) &&
                    <SolidHeartIcon onClick={() => likeSong(player?.song?._id, false)}
                                    className="w-8 ml-auto text-white transition cursor-pointer hover:scale-110 hover:text-light"></SolidHeartIcon>}
              </>}
            </div>

          </div>
        </aside>
        <aside
            className={`${videoOpen ? 'bottom-64' : 'bottom-10'} bg-neutral-800 fixed  w-[96vw] left-[2vw] rounded-full shadow  lg:hidden justify-evenly pl-8 pr-5 py-2 flex min-w-0`}>

          <div className="flex-1 my-auto max-w-[50%]">
            <div className="text-xs font-semibold truncate text-light">{player.song?.chart}</div>
            <div className="text-sm font-medium text-white truncate">{player.song?.name}</div>
            <div className="text-xs truncate text-light">{player.song?.artist}</div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative">
              {addToPlaylistOpen && <div
                  className="absolute flex flex-col w-64 overflow-hidden text-white transition duration-300 rounded-lg shadow animate-in fade-in -right-32 bottom-10 bg-slate-800">

                {playlists?.map(x => <div onClick={() => {
                  addToPlaylist(x._id, player.song?._id);
                  setAddToPlaylistOpen(false)
                }} className="p-3 transition cursor-pointer hover:bg-slate-900">{x.name}</div>)}

                <div onClick={() => {
                  navigate('/create-playlist/' + player.song?._id);
                  setAddToPlaylistOpen(false)
                }} className="p-3 transition cursor-pointer hover:bg-slate-900">Create new playlist...
                </div>
              </div>}
            </div>
            <div className="flex flex-col items-end justify-center space-y-2">
              {player.song && <div>
                <XMarkIcon onClick={() => setAddToPlaylistOpen(!addToPlaylistOpen)}
                           className="w-6 transition rotate-45 cursor-pointer text-light hover:text-white hover:scale-110"/>
              </div>}
              {player.song && <>
                <TvIcon onClick={() => setVideoOpen(!videoOpen)}
                        className="w-6 ml-auto transition cursor-pointer text-light hover:scale-110 hover:text-white"/>
              </>}
            </div>
            <div className="flex flex-col items-end justify-center space-y-2">
              <div>
                {player.song && <>
                  <ShareIcon onClick={() => setShareOpen(true)}
                             className="w-6 ml-auto transition cursor-pointer text-light hover:scale-110 hover:text-white"/>
                </>}
              </div>

              <div>
                {player.song && <>
                  {!userData?.likedSongs?.find(x => x.song == player?.song?._id) &&
                      <HeartIcon onClick={() => likeSong(player?.song?._id, true)}
                                 className="w-6 ml-auto transition cursor-pointer text-light hover:scale-110 hover:text-white"></HeartIcon>}
                  {userData?.likedSongs?.find(x => x.song == player?.song?._id) &&
                      <SolidHeartIcon onClick={() => likeSong(player?.song?._id, false)}
                                      className="w-6 ml-auto text-white transition cursor-pointer hover:scale-110 hover:text-light"></SolidHeartIcon>}
                </>}
              </div>


            </div>
          </div>

          <div className="flex items-center justify-center ml-5">
            <div className="flex flex-col items-center justify-center w-full select-none">
              <div className="flex items-center">
                <div onClick={() => previousSong()}
                     className="ml-auto text-white transition cursor-pointer hover:scale-105"><ChevronDoubleLeftIcon
                    className="w-6"></ChevronDoubleLeftIcon></div>

                <div className="p-2 mx-1 bg-purple-500 rounded-full cursor-pointer hover:bg-purple-400"
                     onClick={() => togglePlayer()}>

                  {videoLoading == -1 && <div role="status">
                    <svg aria-hidden="true"
                         className="w-4 h-4 text-purple-500 animate-spin dark:text-gray-600 fill-light"
                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"/>
                      <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"/>
                    </svg>
                  </div>}

                  {(videoLoading == 1 || videoLoading == 3) &&
                      <PauseIcon className="w-4 h-4 ml-px text-light"></PauseIcon>}
                  {(videoLoading == 2 || videoLoading == 5) &&
                      <PlayIcon className="w-4 h-4 ml-px text-light"></PlayIcon>}

                </div>

                <div onClick={() => nextSong()}
                     className="mr-auto text-white transition cursor-pointer hover:scale-105"><ChevronDoubleRightIcon
                    className="w-6"></ChevronDoubleRightIcon></div>

              </div>


              <div className="mt-2 text-xs font-medium text-light">
                {player.song && (!player.isPlaying || currentTime > 0) &&
                    <div className="w-20 text-center">{formatSeconds(currentTime)}</div>}
                {videoLoading == -1 && <div className="w-20 text-center">Loading...</div>}
              </div>

            </div>

          </div>

        </aside>


      </div>
      <Tooltip id="my-tooltip"/>
    </>
  )
}
