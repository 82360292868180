import { Disclosure } from '@headlessui/react';
import { HeartIcon, MinusSmallIcon, PlusSmallIcon, ShareIcon, TvIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import logoDesign from './kazang-logo.jpg';
import logo from './logo-dark.png';

const faqs = [
  {
    question: "What are the key features on KaZang’s “Home” page?",
    answer:
      <div>


        <p className="my-3 ">At the top of the page is what designers call a “carousel.” Sometimes it is called a
          “slide.” It automatically scrolls. Every five seconds it reveals a new thumbnail for
          a Number One song from one of the 25 countries listed on the sidebar.</p>

        <p className="my-3 ">Below the carousel are songs sorted into categories. The category titles are self-
          explanatory: “Popular Songs,” “Trending Songs,” and “Popular in Your Network.”</p>

        <p className="my-3 ">The country categories on the page “body” show you the week’s Top 10 songs in
          that country.</p>

        <p className="my-3 ">When you click on a country listed in the side menu, KaZang shows you a its Top
          100 songs.</p>


      </div>,
  },
  {
    question: "Greeting",
    answer:
      <div>


        <p className="my-3 ">When you log in to KaZang, the Home page greets you. It says “Hi” and your first
          name.</p>

        <p className="my-3 ">The greeting changes every time you sign out. When you sign back in again, it
          still says “Hi,” but it will say it in a different language. It might say “Hej,” which is
          Hi in Swedish, or “Olá,” which is Spanish, or “Jambo,” Swahili.</p>

        <p className="my-3 ">KaZang says “Hi” in all the languages represented on the platform, switching
          between them each time you log out and log back in.</p>

      </div>,
  },
  {
    question: "How do I play a song?",
    answer:
      <div>

        <p className="my-3 ">To hear a song, move your cursor over a song’s thumbnail and a Play button will
          appear. When you click with you mouse or tap it on your phone, it will activate the
          playback master control button at the bottom of the page. What happens next
          depends on whether you are using KaZang on a laptop, an Android phone, or an
          iPhone or iPad.</p>

        <p className="my-3 ">On a laptop, the song will play after tapping the Play button on the thumbnail.
          KaZang works the same way on an Android smartphone.</p>


        <p className="my-3">Tap the master Play button and the song will play. Use the same button if you
          want to pause the song. The bar beneath the button is what web designers call a
          “scrubber.” It enables you to advance through a song.</p>

        <p className="my-3">To hear another song, go to the song you want to hear and tap Play. This will
          override the current playback. The selected song will play.</p>

        <p className="my-3">iPhone is a different story. KaZang’s MVP uses Google/YouTube technologies, for
          now. This is fine for Android, which is an operating system created by Google,
          but it creates compatibility issues with KaZang’s playback system in IOS.</p>

        <p className="my-3">When you play a song on iPhone, you may see a spinning wheel instead of the
          master Play button. This doesn’t happen all the time. If it does, here is a
          workaround. Don’t wait for the spinning to stop. Tap the wheel and the spinning
          will stop and the Play button will appear. Tap the Play button and voilà …. music!</p>

        <p className="my-3 ">To see a song’s video, click on the Video camera icon <TvIcon className="inline w-6" /> on the right-hand side of
          the master control bar. The video will pop up from the bottom of your screen. To
          stop watching video, click on the Video icon <TvIcon className="inline w-6" /> again. This returns the video
          player to its hidden position.</p>
      </div>,
  },
  {
    question: "What happens when a song is over?",
    answer:
      <div>


        <p className="my-3 ">When a song is over, KaZang automatically plays another song. If you tapped on
          a song on the Number 1 Songs carousel. KaZang will continue playing after it’s
          over, randomly skipping around to different songs on the platform. This feature
          introduces you to hit songs from around the world without you having to search
          for them.</p>
        <p className="my-3 ">You can also play your Liked Songs and the Country charts like playlists.</p>

        <p className="my-3 ">The navigation arrows (on either side of the Play/Pause button) enable you to
          manually skip to another song. The left arrows take you back to the previous
          song. The right arrows will start a different song each time you tap them.</p>

        <p className="my-3 ">If you sample a song, and after a few seconds you’ve heard enough, use the
          “scrubber.”</p>
        <p className="my-3 ">KaZang plays continuously until you pause a song or leave the platform.</p>


      </div>,
  },
  {
    question: "What happens when I click on the Heart icon?",
    answer:
      <div>

        <p className="my-3 ">If you hear a song you like and want to save it to your Library, click the Heart icon <HeartIcon className="inline w-6" />
          on the master control bar. KaZang will add the song to your Library’s “Liked
          Songs.”</p>


      </div>,
  },
  {
    question: "How do I share a song?",
    answer:
      <div>


        <p className="my-3 ">We believe sharing is also one of the most effective and efficient ways to discover
          new songs.</p>

        <p className="my-3 ">There are two ways to do this using KaZang. To share a song, in-app, go to the
          Friends page. In the top right-hand corner, there is a button that says “Invite
          Someone New.” Tapping it opens a pop-up that says, “Invite a Friend.” There is a
          bar to enter your friend’s email address. After you do that, tap the purple “Invite
          User” button below the “Email Address” bar.</p>

        <p className="my-3 ">After your friends register and complete profiles, you can go back to the Friends
          page and use the Search bar to find them. This will pull up a thumbnail from their
          profile and an icon to add invited them to join your KaZang network.</p>

        <p className="my-3 ">After they accept, your friends can see all the “Liked Songs” songs in your
          “Library.”</p>

        <p className="my-3 ">A second way to share is with the Share icon <ShareIcon className="inline w-6" /> on the master control bar. When
          you click on the Share <ShareIcon className="inline w-6" /> icon, a pop-up will appear. It features three ways to
          share the song you are playing – Facebook, WhatsApp and Telegram.</p>


        <p className="my-3 ">To close the pop-up, click anywhere outside the pop-up.</p>


      </div>,
  },
  {
    question: "What does KaZang do?",
    answer:
      <div>

        <p className="my-3 ">KaZang is the world’s only social network devoted to discovering the world’s
          biggest hits in pop, rock, hip-hop, reggaeton, electronic music – in languages
          spoken on six continents.</p>
        <p className="my-3 ">We created KaZang in the belief that people can enjoy a song even when they
          don’t understand the lyrics. We are starting with music from 25 of the world’s
          most influential music markets. Those of you who are native English speakers
          may be surprised to learn that English is an official language in eight of the 20
          countries.</p>
        <p className="my-3 ">When KaZang introduces you to a song from Nigeria (English, by the way, is
          Nigeria’s sole official language), we think you’ll be delighted by how much you
          enjoy a Nigerian Afrobeats song in Yoruba (one of the country’s tribal languages)
          or an Afrobeat song from Tanzania or Kenya in Swahili.</p>

        <p className="my-3 ">English is an official national language in South Africa. But it was an isiZulu
          song, Jerusalema, that got people around the world dancing. English is also the
          second official language in India, but a Telugu-language song from southeastern
          India, Naatu Naatu, made history in 2023 by being the first Indian song to win an
          Oscar for Best Original Song.</p>
        <p className="my-3 ">Lyrics matter, of course. Some of the world’s greatest pop artists, like Taylor
          Swift, Ed Sheeran, The Beatles, Paul Simon, Carole King, Elton John (&amp; Bernie
          Taupin), and Buddy Holly are legendary wordsmiths in English. Their words

          beguile. Their melodies enchant. Their songs connect with people around the
          world – even when they don’t speak English.</p>
        <p className="my-3 ">KaZang celebrates music – in your language, and in languages you don’t speak.</p>

      </div>,
  },
  {
    question: "What is the symbolism behind KaZang’s “K”?",
    answer:
      <div>


        <p className="my-3 ">When you see the KaZang logo, you’re looking at artistic influences from four continents
          – South America, North America, Europe, and Asia.</p>

        <p className="my-3 ">Our icon is a letter “K” – but not just any K. The spine of the KaZang “K” – its
          “stem” –is a musical note – a “Bb” on the B Flat Major scale.</p>

        <p className="my-3 ">The “arm” and “leg” of the “K” are curved to emulate a classical Indian dance pose. The
          designer created this illustration to help you visualize the source of his inspiration.</p>


        <img src={logoDesign} className="py-5"></img>


        <p className="my-3 ">What this illustration doesn’t show is the creators’ cultural DNA. The logo’s
          project manager is an American who lives in Mexico. She commissioned the logo
          from an Argentinian designer who lives in Spain. He looked for inspiration in
          Indian art.</p>

      </div>,
  },
  {
    question: "KaZang is an “MVP.” What does that mean?",
    answer:
      <div>


        <p className="my-3 ">You are testing an MVP. This is an acronym for “Minimum Viable Product,” or “MVP.” An MVP is a stripped-down, no-frills version of the final product that KaZang plans to build.</p>

        <p className="my-3 ">MVPs are experiments. They test a product’s core assumptions. Are they valid, or not? Instead of committing funds to untested ideas, companies build MVPs to see if people (the market) like the product. If large numbers do – a mass market – companies call this “product market fit.”</p>

        <p className="my-3 ">This rarely happens overnight. So, companies try to connect to mass markets through a trial-and-error process that begins with building an MVP. When a company makes quick adjustments to accommodate user preferences – and possibly incorporate their suggestions – the process is called “iterative.”</p>

        <p className="my-3 ">“User experience,” or “UX,” refers to what people experience when they use an online or mobile technology. Web and mobile executives pay close attention to UX. They listen to their customers. Based on UX feedback, companies adjust their products to make them more user-friendly. Each iteration is a response to customer feedback and another opportunity to learn more from customers.</p>

        <p className="my-3 ">A product can go through many iterations before reaching its “beta” stage. The beta is a prototype that has most of the features of a final product.</p>

        <p className="my-3 ">So, you are part of KaZang’s “lean” development process – and we thank you. Making quick adjustments makes a company “agile.” To cater to you, our MVP testers (and we hope eventual customers), KaZang aims to be responsive, lean, and agile.</p>

      </div>,
  },
]

export default function FAQs() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(true)
  useEffect(() => {
    if (!localStorage.getItem('token'))
      setLoggedIn(false)
  }, [])

  return (<div className="flex flex-col h-full max-w-6xl mx-auto">
    <div className="flex flex-col w-full max-w-6xl mx-auto">
      <div className="flex items-center justify-between">
        <div className="my-8 text-5xl font-bold text-white"><img className="inline w-[190px] pb-1 mr-3" src={logo}></img> FAQs</div>
        {!loggedIn && <div className="flex items-center space-x-3">
          <div className="font-semibold cursor-pointer text-light opacity-70" onClick={() => navigate('/login')}>Log in</div>
          <div className="p-3 px-5 font-semibold text-black bg-white rounded-full cursor-pointer" onClick={() => navigate('/register')}>Sign Up</div>
        </div>}
      </div>

      <dl className="mt-10 space-y-6 divide-y divide-white/10">
        {faqs.map((faq) => (
          <Disclosure as="div" key={faq.question} className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex items-start justify-between w-full text-left text-white">
                    <span className="text-lg font-semibold leading-7">{faq.question}</span>
                    <span className="flex items-center ml-6 h-7">
                      {open ? (
                        <MinusSmallIcon className="w-6 h-6" aria-hidden="true" />
                      ) : (
                        <PlusSmallIcon className="w-6 h-6" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="pr-12 mt-2">
                  <p className="font-medium leading-7 text-light">{faq.answer}</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>

      <div className="mt-10 text-center">
        <div className="my-3 text-3xl font-bold text-light">Need to contact us?</div>
        <div className="my-3 text-xl font-medium text-light">Send suggestions to: <a href="mailto:feedback@kazang.co">feedback@kazang.co</a></div>
        <div className="my-3 text-xl font-medium text-light">Send requests for technical support here: <a href="mailto:support@kazang.co">support@kazang.co</a></div>
        <div className="my-3 text-xl font-medium text-light">We’ll get back to you as soon as we can.</div>
      </div>

    </div>
  </div>)
}